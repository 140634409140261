<template>
  <main>
    <TailwindDataTable
      :loading="isLoading"
      :tableData="trackedLoans"
      :renderedItems="getRenderedItems"
      title="Loan Reporting Page"
      @search="search = $event"
      :params="payload"
      @update="updateData"
      :cycle="cycle"
      @pageChange="updatePayload"
    >
    </TailwindDataTable>
  </main>
</template>

<script>
import moment from 'moment'
import { debounce } from '../utils/helper'

export default {
  data() {
    return {
      trackedLoans: [],
      isLoading: false,
      moment,

      cycle: 0,
      payload: {
        search: '',
        pageNumber: '1',
        pageSize: '100',
        hasNextPage: false,
        hasPrevPage: false,
        totalCount: '',
        totalPages: '',
      },
      renderedItems: [
        { name: 'Borrower Name', key: 'borrower_name' },
        { name: 'Borrower Phone ', key: 'borrower_phone' },
        { name: 'Business Name', key: 'business_name' },
        { name: 'Tenor', key: 'tenor' },
        { name: 'VFD Account Number', key: 'repayment_fixed_account_number' },
        { name: 'BVN', key: 'bvn' },
        { name: 'Loan Type', key: 'loan_type_name' },
        { name: 'Referring Officer', key: 'referring_officer' },
        { name: 'Date of Incorporation', key: 'date_of_incorporation' },
        { name: 'Distribution Date', key: 'distribution_date' },
        { name: 'Interest Rate', key: 'interest_rate' },
        { name: 'Loan Amount', key: 'loan_amount' },
        { name: 'Loan Amount Outstanding', key: 'loan_amount_outstanding' },
        { name: 'Loan Due', key: 'loan_due_to_collect_today' },
        { name: 'Loan Status', key: 'loan_status_name' },
        { name: 'Repayment Start Date', key: 'repayment_start_date' },
      ],
      search: '',
    }
  },
  watch: {
    search: debounce(function(newVal) {
      this.payload.search = newVal.toLowerCase()
      this.payload.pageNumber = '1'
      this.payload.pageSize = '100'
      this.payload.hasNextPage = false
      this.payload.hasPrevPage = false
      this.payload.totalCount = ''
      this.payload.totalPages = ''

      this.getAllTrackedLoans()
    }, 3000),
  },
  methods: {
    updatePayload(payload) {
      this.payload = {
        ...this.payload,
        pageNumber: payload.toString(),
      }
      this.getAllTrackedLoans()
    },
    getAllTrackedLoans() {
      const body = this.payload
      this.isLoading = true
      this.$store
        .dispatch('LOAN_DASHBOARD', body)
        .then((res) => {
          let data = res.data.data
          data.forEach((obj, index) => {
            Object.keys(obj).forEach((key) => {
              if (typeof obj[key] === 'string') {
                let momentDate = moment(obj[key])
                if (momentDate.isValid()) {
                  data[index][key] = momentDate.format('MM/DD/YYYY')
                }
              }
            })
          })
          this.trackedLoans = data
          let {
            hasNextPage,
            hasPrevPage,
            totalCount,
            totalPages,
            pageNumber,
            pageSize,
          } = res.data.meta
          this.payload = {
            ...this.payload,
            hasNextPage,
            hasPrevPage,
            totalCount: totalCount.toString(),
            totalPages: totalPages.toString(),
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString(),
          }
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
        })
    },
    updateData(payload) {
      const data = payload.map((item) => {
        return {
          name: item.name,
          key: item.value,
        }
      })
      this.renderedItems = data
      this.cycle += 1
    },
  },
  computed: {
    getRenderedItems() {
      return this.renderedItems
    },
  },
  async created() {
    await this.getAllTrackedLoans()
  },
}
</script>
